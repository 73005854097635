import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ResponseError, PageHeaderGraphql, PageHeader } from '@models';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  public getPortalPageHeaderByTag(tag: string): Observable<any> {
    return this.apollo.query({
      query: PageHeaderGraphql.queryGetPortalPageHeaderByTag,
      variables: {
        tag
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable('getPortalPageHeaderByTag')),
    );
  }

  public saveCarrosselPhotos(tag: string, title: string, image: string) {
    return this.apollo.mutate<any>(
      {
        mutation: PageHeaderGraphql.mutationUpdatePortalPageHeaderImage,
        variables: {
          tag,
          image
        }
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<PageHeader>('updatePortalPageHeaderImage')),
      );
  }

}
