import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { AppConstants } from '@utils/app-constants';

import { AlertMessageService } from './alert-message.service';
import { GymUnit, ResponseError, GymUnitGraphql, AlertMessage } from '@models';
import { JSONUtil } from '@utils/json-util';

import { map } from 'rxjs/operators';

@Injectable()
export class TagService {

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly apollo: Apollo,
  ) { }

  public preProccessToSave(updatedTag: string[], gymUnit: GymUnit) {
    gymUnit.searchTags = updatedTag.join(',');

    return this.save(gymUnit);
  }

  private save(gymUnit: GymUnit) {
    return this.apollo.mutate<any>(
      {
        mutation: GymUnitGraphql.mutationUpdateSearchTags,
        variables: {
          id: gymUnit.id,
          searchTags: gymUnit.searchTags,
        },
      }).pipe(
        map(JSONUtil.turnApolloMutable<ResponseError[]>()),
      );
  }
}
