import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlanInfo, PlanPriceListGraphql } from '@models/plan-price-list.model';
import { JSONUtil } from '@utils/json-util';

import { Apollo } from 'apollo-angular';

import { Brand } from './brand.service';


@Injectable({
  providedIn: 'root'
})
export class PlanPriceListService {
  fitnessMallPlans: number[] = [144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155];
  platinumPlans: number[] = [6, 12, 75, 76];

  fixedTaxGroups: { name: string, taxValue: number }[] = [
    { name: 'Fitness', taxValue: 205 },
    { name: 'Fitness Estadual', taxValue: 205 },
    { name: 'Fitness Exclusive', taxValue: 205 },
    { name: 'BT Master', taxValue: 175 },
    { name: 'Student Plan', taxValue: 175 },
    { name: 'Student Plan Plus', taxValue: 175 },
    { name: 'Kids', taxValue: 205 },
    { name: 'Baby', taxValue: 205 },
  ];

  constructor(
    private readonly apollo: Apollo,
  ) { }

  listAllGroupsByGymUnit(gymUnitId: number): Observable<PlanInfo[]> {
    return this.apollo.query({
      query: PlanPriceListGraphql.queryListAllGroupsByGymUnit,
      variables: {
        gymUnitId
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<{listAllGroupsByGymUnit: PlanInfo[]}>()),
      map((res) => res.listAllGroupsByGymUnit),
    );
  }

  private regroupFitnessPlans(plans: PlanInfo[]): PlanInfo[] {
    const fitnessStateGroup: PlanInfo = {
      id: 172,
      name: 'Fitness Estadual',
      extraText: null,
      taxText: null,
      taxValue: null,
      parkingText: 'Estacionamento gratuito por 3 horas',
      observation: null,
      plans: [],
    };

    plans.forEach(group => {
      const fitnessStateId = 172;
      const fitnessStateTotalId = 176;

      if (group.id === 3) {
        const indexFitnessState = group.plans.indexOf(group.plans.find(plan => plan.id === fitnessStateId));
        const fitnessStatePlan = group.plans[indexFitnessState];

        if (indexFitnessState >= 0) {
          group.plans.splice(indexFitnessState, 1);
          fitnessStateGroup.plans.push(fitnessStatePlan);
        }
      }

      if (group.id === 4) {
        const indexFitnessStateTotal = group.plans.indexOf(group.plans.find(plan => plan.id === fitnessStateTotalId));
        const fitnessStateTotalPlan = group.plans[indexFitnessStateTotal];

        if (indexFitnessStateTotal >= 0) {
          group.plans.splice(indexFitnessStateTotal, 1);
          fitnessStateGroup.plans.push(fitnessStateTotalPlan);
        }
      }
    });

    if (fitnessStateGroup.plans.length > 0) {
      plans.push(fitnessStateGroup);
    }

    return plans;
  }

  filterPlanList(plans: PlanInfo[], brand: Brand): PlanInfo[] {
    if (plans && plans.length && brand === 'bt') {
      const superKids = 'Super Kids';
      let filteredList = plans.filter(group => group.name.toUpperCase() !== superKids.toUpperCase());

      filteredList = this.regroupFitnessPlans(filteredList);
      filteredList.forEach(group => {
        group.plans = group.plans.filter(plan => {
          if (!this.fitnessMallPlans.find(planId => planId === plan.id)
            && !this.platinumPlans.find(planId => planId === plan.id)) {
            return plan;
          }
        });

        if (group.id === 10) {
          const studentPlan = 116;
          const studentPlanParcial = 128;

          const indexStudentPlan = group.plans.indexOf(group.plans.find(plan => plan.id === studentPlan));
          const indexStudentPlanParcial = group.plans.indexOf(group.plans.find(plan => plan.id === studentPlanParcial));

          if (indexStudentPlanParcial >= 0) {
            group.plans[indexStudentPlanParcial].shortName = 'Student Plan';
            if (indexStudentPlan >= 0) {
              group.plans.splice(indexStudentPlan, 1);
            }
          }
        }

        // Seta os valores de taxa de adesão utilizando o array fixedTaxGroups
        const groupWithFixedTax = this.fixedTaxGroups.find(fixedTaxgroup => fixedTaxgroup.name === group.name);
        group.taxValue = groupWithFixedTax ? groupWithFixedTax.taxValue : 0;
      });

      return filteredList.filter(group => group.plans && group.plans.length > 0);
    }

    return [];
  }
}
