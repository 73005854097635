import { Component, OnInit, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Teacher } from '@models/teacher';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TagInputComponent } from 'ngx-chips';
import { FormUtil } from '@utils/form-util';
import { AlertMessageService, DialogService } from '@services';
import { AlertMessage, MessageMap, Activity, ActivityGroup } from '@models';
import { MultipleSelectModalComponent } from '@sharedcomponents';
import { StringUtil } from '@utils/string.util';

@Component({
  selector: 'app-teachers-edit-form',
  templateUrl: './teachers-edit-form.component.html',
  styleUrls: ['./teachers-edit-form.component.scss']
})
export class TeachersEditFormComponent implements OnInit, AfterViewInit {

  messageNoLinkedUnit = 'Nenhuma atividade vinculada.';
  teacherForm: FormGroup;
  isInvalidTagInput = false;

  @Input() teacherToEdit: Teacher;
  @Input() allActiveActivities: Activity[];
  @Input() allActivityGroups: ActivityGroup[];
  @Input() isEdit: boolean;

  @Output() public teacherEmitter: EventEmitter<Teacher> = new EventEmitter();


  @ViewChild('tagInputTeacherActivities') tagInputTeacherActivities: TagInputComponent;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly alertMessageService: AlertMessageService,
    private readonly dialogService: DialogService
  ) { }

  ngOnInit() {
    this.teacherToEdit.activities.sort((a, b) => {
      return StringUtil.strcmp(a.name, b.name);
    });
    this.teacherForm = this.formBuilder.group({
      id: new FormControl(this.teacherToEdit.id),
      name: new FormControl({ value: this.teacherToEdit.name, disabled: true }),
      reducedName: new FormControl({ value: this.teacherToEdit.reducedName, disabled: true }),
      activities: new FormControl({ value: this.teacherToEdit.activities, disabled: true }, [Validators.required]),
      active: new FormControl(this.teacherToEdit.active),
      dirty: new FormControl(this.teacherToEdit),
      gymUnitTeachers: new FormControl(this.teacherToEdit.gymUnitTeachers),
      gymUnities: new FormControl({ value: this.getGymNames(), disabled: true })
    });
  }

  ngAfterViewInit() {
    this.tagInputTeacherActivities.inputForm.input.nativeElement.disabled = true;
  }

  save() {
    FormUtil.touchForm(this.teacherForm);
    this.updateInvalidTagInput();
    if (this.teacherForm.valid) {
      this.teacherForm.controls.name.enable();
      this.teacherForm.controls.reducedName.enable();
      this.teacherForm.controls.active.enable();
      this.teacherForm.controls.activities.enable();
      this.teacherEmitter.emit(this.teacherForm.value);
      this.teacherForm.reset();
    } else {
      this.teacherForm.markAsTouched();
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
    }
  }

  addActivity(): void {
    if (this.isEdit) {
      this.allActiveActivities = this.allActiveActivities.map(activity => {
        activity.owner = activity.activityGroup.id;
        return activity;
      });
      this.teacherToEdit.activities = this.teacherToEdit.activities.map(activity => {
        activity.owner = activity.activityGroup.id;
        return activity;
      });

      const teacherActivities = this.teacherForm.get('activities').value.slice();
      let selectedItemId = null;
      if (teacherActivities.length) {
        teacherActivities.sort((a, b) => {
          return StringUtil.strcmp(
            a.activityGroup.name,
            b.activityGroup.name,
          );
        });
        selectedItemId = teacherActivities[0].activityGroup.id;
      }

      this.dialogService.openDialog(MultipleSelectModalComponent, {
        width: '796px',
        maxHeight: '90vh',
        data: {
          modalTitle: 'SELECIONE AS ATIVIDADES',
          items: this.allActivityGroups,
          subItems: this.allActiveActivities,
          selectedSubItems: this.teacherForm.get('activities').value,
          selectedItemId
        },
      });
      this.dialogService.afterClosed().subscribe(
        sItens => {
          if (sItens) {
            sItens.sort((a, b) => {
              return StringUtil.strcmp(a.name, b.name);
            });
            this.teacherForm.get('activities').setValue(sItens);
            this.teacherForm.get('activities').markAsTouched();
            this.updateInvalidTagInput();
          }
        }
      );
    }

  }

  updateInvalidTagInput(): void {
    const control = this.teacherForm.get('activities');
    this.isInvalidTagInput =
      control.touched && (!control.value || (!!control.value && !control.value.length));
  }

  private getGymNames() {
    let gymUnities = '';
    if (this.teacherToEdit.gymUnitTeachers && this.teacherToEdit.gymUnitTeachers.length) {
      this.teacherToEdit.gymUnitTeachers.sort((gymA: any, gymB: any) => {
        return StringUtil.strcmp(gymA.gymUnit.name, gymB.gymUnit.name);
      });
      this.teacherToEdit.gymUnitTeachers.forEach((gym: any) => {
        if (gymUnities) {
          gymUnities = gymUnities + '; ';
        }
        gymUnities = gymUnities + gym.gymUnit.name;
      });
      gymUnities = gymUnities + '.';
    }
    return gymUnities;
  }
}
