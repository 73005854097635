import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Teacher } from '@models/teacher';
import { Activity, ActivityGroup } from '@models';

@Component({
  selector: 'app-teachers-edit-modal',
  templateUrl: './teachers-edit-modal.component.html',
  styleUrls: ['./teachers-edit-modal.component.scss']
})
export class TeachersEditModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TeachersEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  teacherToEdit: Teacher;
  allActiveActivities: Activity[];
  allActivityGroups: ActivityGroup[];
  isEdit: boolean;

  ngOnInit() {
    this.teacherToEdit = this.data.teacherToEdit;
    this.allActiveActivities = this.data.allActiveActivities;
    this.allActivityGroups = this.data.allActivityGroups;
    this.isEdit = this.data.isEdit;

  }

  closeModal(): void {
    this.dialogRef.close();
  }

  editTeacher(teacher: Teacher) {
    this.dialogRef.close(teacher);
  }

}
