import { Component, OnInit, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScrollService } from '@services/scroll.service';

@Component({
  selector: 'app-sided-menu-generic-modal',
  templateUrl: './sided-menu-generic-modal.component.html',
  styleUrls: ['./sided-menu-generic-modal.component.scss']
})

export class SidedMenuGenericModalComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() hasAsideMenu = true;

  @ViewChild('mainModal') mainModal: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<SidedMenuGenericModalComponent>,
    private scrollService: ScrollService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.scrollService.needScroll$.subscribe(res => {
      this.mainModal.nativeElement.scrollTop = 0;
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
