import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { map } from 'rxjs/operators';
import { JSONUtil } from '@utils/json-util';

import { SocialMediaGraphql, SocialMedia } from '@models/social-media';
import { Observable } from 'rxjs';
import { GymUnitSocialMedia, GymUnitSocialMediaGraphql, GymUnit, GymUnitSocialMediaUpdate, ResponseError, AlertMessage } from '@models';
import { AlertMessageService } from './alert-message.service';

@Injectable()
export class SocialMediaService {

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly apollo: Apollo
  ) { }

  public getListSocialMedias(): Observable<SocialMedia[]> {
    return this.apollo.query<SocialMedia[]>({
      query: SocialMediaGraphql.queryListSocialMedias,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<SocialMedia[]>('listSocialMedias')),
    );
  }

  public getListGymUnitSocialMediasByGymUnit(gymUnit: GymUnit): Observable<GymUnitSocialMedia[]> {
    return this.apollo.query<GymUnitSocialMedia[]>({
      query: GymUnitSocialMediaGraphql.queryListGymUnitSocialMediasByGymUnit,
      variables: {
        gymUnitId: gymUnit.id
      }
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<GymUnitSocialMedia[]>('listGymUnitSocialMediasByGymUnit')),
    );
  }

  public preProccessToSave(gymUnitId: number, gymUnitSocialMedia: GymUnitSocialMedia[]) {
    const gymUnitSocialMediaUpdate: GymUnitSocialMediaUpdate[] = this.formatToGymUnitSocialMediaUpdate(gymUnitSocialMedia);

    return this.save(gymUnitId, gymUnitSocialMediaUpdate);
  }

  public save(gymUnitId: number, gymUnitSocialMediaUpdate: GymUnitSocialMediaUpdate[]) {
    return this.apollo.mutate<GymUnitSocialMediaUpdate>(
      {
        mutation: GymUnitSocialMediaGraphql.mutationAssociateGymUnitSocialMediasToGymUnit,
        variables: {
          gymUnitId,
          gymUnitSocialMedias: gymUnitSocialMediaUpdate,
        }
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<ResponseError[]>()),
      );
  }

  private formatToGymUnitSocialMediaUpdate(gymUnitSocialMedias: GymUnitSocialMedia[]): GymUnitSocialMediaUpdate[] {
    const gymUnitSocialMediaUpdate =
      gymUnitSocialMedias.map((social, index) => {
        return {
          url: social.url.trim(),
          display: social.display.trim(),
          displayOrder: index,
          socialMediaId: social.socialMedia as number,
        };
      });

    return gymUnitSocialMediaUpdate;
  }
}
