import { Injectable, Injector } from '@angular/core';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppConstants } from '@utils/app-constants';
import { AuthenticateGraphql } from '@models/authenticate';
import { BaseResourseLoginService } from 'app/shared/services/base-resource-login.service';
import { BaseResourceLoginModel } from 'app/shared/models/base-resource-login.model';
import { AppointmentLiveClassGraphql, ClientContactData, OnlineClassSchedule, ResponseError } from '@models';
import { PortalEndpoints } from '@utils/app-endpoints';
import { environment } from '@env/environment';

const params = {
  graphqlEnvironment: 'portal',
  key: AppConstants.STOR_PORTAL_TOKEN_APPOINTMENT_LIVE_CLASS,
  query: AuthenticateGraphql.queryTeacherLogin,
  url: '/auth',
};

@Injectable({
  providedIn: 'root'
})
export class AppointmentLiveClassService extends BaseResourseLoginService<BaseResourceLoginModel> {

  constructor(
    protected injector: Injector,
  ) {
    super(params, injector);
  }

  public getAllAppointmentLiveClassesByTeacher(teacherId: string): Observable<any> {
    return this.apollo.use('portal').query({
      query: AppointmentLiveClassGraphql.queryListAllAppointmentLiveClassByTeacher,
      variables: {
        teacherId,
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<any>('listAllAppointmentLiveClassByTeacher')),
    );
  }

  public getClientContacts(clientRegistration: number): Observable<ClientContactData> {
    return this.apollo.use('portal').query({
      query: AppointmentLiveClassGraphql.queryListCustomerPhones,
      variables: {
        registration: clientRegistration
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable<ClientContactData>()),
    );
  }

  public updatePresence(appointment: OnlineClassSchedule, status: boolean): Observable<boolean> {
    return this.apollo.use('portal').mutate({
      mutation: AppointmentLiveClassGraphql.mutationUpdatePresence,
      variables: {
        id: appointment.id,
        registration: appointment.clientRegistration,
        status
      }
    }).pipe(
      map(JSONUtil.turnApolloMutable<{ presence: boolean }>('updateAppointmentLiveClassPresence')),
      map(data => {
        if (!!data) {
          return data.presence;
        } else {
          throw Error('Erro inesperado no serviço');
        }
      })
    );
  }

  public getZoomRoomInformation(zoomLink: string, type: string): Observable<any> {
    return this.http.post<any>(
      environment.portal + PortalEndpoints.ZoomRoomInfo,
      { meetingId: zoomLink, type }
    );
  }

}
