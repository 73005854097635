import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-teacher-modal',
  templateUrl: './select-teacher-modal.component.html',
  styleUrls: ['./select-teacher-modal.component.scss', '../sided-menu-generic-modal.component.scss']
})
export class SelectTeacherModalComponent implements OnInit {

  public teachers: any[];
  public selectedTeacher: string;
  searchText = '';

  constructor(
    public dialogRef: MatDialogRef<SelectTeacherModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.teachers = this.data.teachers;
    const storageTeacher = this.data.selectedTeacher;
    if (storageTeacher) {
      this.selectedTeacher   = storageTeacher.id;
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  public selectTeacher(teacherId: string): void {
    this.selectedTeacher = teacherId;
    const st = this.data.teachers.find(teachers => teachers.id === this.selectedTeacher);
    this.dialogRef.close(st);
  }
}
