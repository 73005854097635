import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockUiService, Brand } from '@services';
import { BlockUIAction } from '@models';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { BrandService } from '@services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { read: true }) toastContainer: ToastContainerDirective;

  @BlockUI() blockUI: NgBlockUI;

  brand: Brand;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly blockUiService: BlockUiService,
    private readonly brandService: BrandService,
    private readonly toastrService: ToastrService,
  ) {
    this.blockUiService.emitStatus.subscribe(status => {
      switch (status.action) {
        case BlockUIAction.START:
          this.blockUI.start();
          break;
        case BlockUIAction.START_CUSTOM_MESSAGE:
          this.blockUI.start(status.message);
          break;

        case BlockUIAction.STOP:
          this.blockUI.stop();
          break;
        case BlockUIAction.NETWORK_ERROR:
          this.blockUI.stop();
          // to do - verificar se é interessante colocar algo parecido no backoffice
          // this.networkErrorComponent.openModal();
          break;
      }
    });

    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;

    const link = this.document.createElement('link');

    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href=`assets/favicons/${this.brand}/favicon_114.png`;
    this.document.head.appendChild(link);
  }
}
