import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { PeriodizationGraphql, PeriodizationFilterResult, Periodization,
  PeriodizationDomain, PeriodizationUpdate, PeriodizationItemUpdate } from '@models';
import { Observable } from 'rxjs';

@Injectable()
export class PeriodizationService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  getPeriodizationByActivityMonthAndYear(filterResult: PeriodizationFilterResult): Observable<Periodization> {
    return this.apollo.query<Periodization>({
      query: PeriodizationGraphql.queryGetPeriodizationByActivityMonthAndYear,
      variables: {
        activityId: filterResult.activityId,
        month: filterResult.month,
        year: filterResult.year
      },
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<Periodization>('getPeriodizationByActivityMonthAndYear')),
    );
  }

  listPeriodizationDomainsByActivity(id: number): Observable<PeriodizationDomain[]> {
    return this.apollo.query<PeriodizationDomain[]>({
      query: PeriodizationGraphql.queryListPeriodizationDomainsByActivity,
      variables: {
        id
      }
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<PeriodizationDomain[]>('listPeriodizationDomainsByActivity')),
    );
  }

  associatePeriodizationToActivity(activityId: number, periodization: PeriodizationUpdate, periodizationItems: PeriodizationItemUpdate[]) {
    return this.apollo.mutate<PeriodizationDomain[]>({
      mutation: PeriodizationGraphql.mutationAssociatePeriodizationToActivity,
      variables: {
        activityId,
        periodization,
        periodizationItems
      }
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<PeriodizationDomain[]>('associatePeriodizationToActivity')),
    );
  }
}
