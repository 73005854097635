import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BrandService } from '@services/brand.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {

  @Input() name = '';
  @Input() check = false;
  @Output() checkChange = new EventEmitter<boolean>();
  @Input() message = '';

  checkboxBgPath = '';

  constructor(private readonly brandService: BrandService) {
    this.checkboxBgPath = `url('../../../../assets/icon_custom/${this.brandService.getBrand()}/check-on.svg')`
   }

  toggle() {
    this.check = !this.check;
    this.checkChange.emit(this.check);
  }
}
