import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  EvoMembershipParamsRequestModel,
  EvoMembershipResponseModel,
  MembershipsToHideListGraphql,
  MembershipToHideModel,
} from '@models/membership.model';
import { PeriodicityInfo, PlanInfo, SubPlanInfo } from '@models/plan-price-list.model';
import { Brand } from '@services/brand.service';
import { evoBack, EvoBackEndpoints } from '@utils/app-endpoints';
import { MembershipOfferFeeType } from '@utils/gym-util';
import { JSONUtil } from '@utils/json-util';
import { evoGetAllPagination } from '@utils/rxjs-operators';

import { Apollo } from 'apollo-angular';


@Injectable()
export class EvoMembershipService {

  readonly commitmentValues = {
    bianual: 18,
    anual: 12,
    semestral: 6,
    trimestral: 3,
    mensal: 1,
    débito: 1,
  };
  readonly commitmentTexts = [
    'bianual',
    'anual',
    'semestral',
    'trimestral',
    'débito'
  ];
  readonly planTitleWhiteList = [
    ' manhã',
    ' noite',
    ' total',
  ];

  readonly fixedTaxPlanBt: { name: string, value: number }[] = [
    { name: 'fitness mall', value: 190 },
    { name: 'fitness', value: 220 },
    { name: 'student', value: 190 },
    { name: 'master', value: 190 },
    { name: 'kids', value: 220 },
    { name: 'baby', value: 220 },
  ];
  readonly fixedTaxPlanFr: { name: string, value: number }[] = [
    { name: 'fitness',	value: 185 },
    { name: 'fitness ct',	value: 185 },
    { name: 'fitness nacional',	value: 185 },
    { name: 'fitness anual', value: 185 },
    { name: 'fitness mall',	value: 120 },
    { name: 'plano estudante',	value: 120 },
    { name: 'plano estudante parcial',	value: 120 }
  ];

  constructor(
    private readonly apollo: Apollo,
    private readonly http: HttpClient,
  ) { }

  /**
   * This service will get all memberships of a gym from EVO database. Recursion was apply to this
   * method because of the service pagination, for each request `skip` attr from `params` will be increased
   * by his total + request response length. The recursion ends when response is empty.
   *
   * @param params - Represent values accept for query params. When use, pay attention to `skip` attr because
   * of his requirement to make pagination work.
   */
  getMemberships({
    gymUnitId = null,
    skip = '0',
    take = '50'
  }: Partial<EvoMembershipParamsRequestModel> = {}):
  Observable<EvoMembershipResponseModel[]> {
    const kidsSubStr = [ 'KIDS', 'BABY' ];

    const filterByKids = (membership: EvoMembershipResponseModel) => {
      return kidsSubStr.some(kids => membership.nameMembership.toLocaleUpperCase().includes(kids));
    };

    return this.getMembershipsRecursive({ gymUnitId, skip, take }).pipe(
      map((memberships) => {
        memberships.filter(
          (membership) =>
            (membership.urlSale || filterByKids(membership)) &&
            !membership.inactive
        );

        return memberships.map((membership) => {
          if (membership.nameMembership.includes('5X') && membership.displayName) {
            membership.displayName = `${membership.displayName} - 5X`;
          }

          return membership;
        });
      }
      )
    );
  }

  private getMembershipsRecursive(params: Partial<EvoMembershipParamsRequestModel>): Observable<EvoMembershipResponseModel[]> {
    return this.http.get<EvoMembershipResponseModel[]>(evoBack(EvoBackEndpoints.Membership), { params: { ...params } }).pipe(
      evoGetAllPagination(params, this.getMembershipsRecursive.bind(this)),
    );
  }

  getHideMembershipList(): Observable<MembershipToHideModel[]> {
    return this.apollo.query({
      query: MembershipsToHideListGraphql.querylistEvoMembershipsToHideOnPriceTable,
    }).pipe(
      map(JSONUtil.turnApolloMutable<{listEvoMembershipsToHideOnPriceTable: MembershipToHideModel[]}>()),
      map((res) => res.listEvoMembershipsToHideOnPriceTable),
    );
  }

  private filterMembershipList(
    memberships: EvoMembershipResponseModel[],
    toHideMemberships: MembershipToHideModel[]
  ): EvoMembershipResponseModel[] {
    toHideMemberships.forEach(toHideItem => {
      const toHideMembershipName = toHideItem.membershipName.toLocaleUpperCase();
      memberships = memberships.filter(membership => !membership.nameMembership.toLocaleUpperCase().includes(toHideMembershipName));
    });

    const filteredList =  memberships.filter(membership =>
      !membership.inactive
      && !membership.nameMembership.toLocaleUpperCase().includes('BT PASS')
      && !membership.nameMembership.toLocaleUpperCase().includes('PROMO'));

    return this.sortMemberships(filteredList);
  }

  private removeCommitmentsFromTitle(title: string): string {
    if (!title) {
      return null;
    }

    let formattedTitle = title.toLowerCase().trim();
    this.commitmentTexts.forEach(commitment => {
      formattedTitle = formattedTitle.replace(commitment, '');
    });

    return formattedTitle;
  }

  private removeDescriptionsFromTitle(title: string): string {
    if (!title) {
      return null;
    }

    let formattedTitle = title.toLowerCase().trim();
    this.planTitleWhiteList.forEach(item => {
      formattedTitle = formattedTitle.replace(item, '');
    });
    return this.removeCommitmentsFromTitle(formattedTitle);
  }

  private separatePlans(
    memberships: EvoMembershipResponseModel[],
    brand: Brand
  ): PlanInfo[] {
    const plans: PlanInfo[] = [];
    memberships.forEach(membership => {
      const { idBranch, displayName } = membership;

      if (!displayName) {
        return;
      }

      const planTitle = this.removeDescriptionsFromTitle(displayName);
      const isAllocated = plans.find(plan => plan.name === planTitle);
      let fixedTaxGroup = null;

      if (brand === 'bt') {
        const taxPlan = this.fixedTaxPlanBt.find(taxGroup => planTitle.includes(taxGroup.name));
        fixedTaxGroup = taxPlan ? {...taxPlan} : { name: '', value: 220 };

        // Shopping Leblon, Iguatemi - São Paulo and Iguatemi - Fortaleza prices are different.
        if ( membership.idBranch === 123
          && fixedTaxGroup.name !== 'student'
          && fixedTaxGroup.name !== 'master'
          && fixedTaxGroup.name !== 'fitness mall'
        ) {
          fixedTaxGroup.value = 275;
        } else if (membership.idBranch === 52) {
          fixedTaxGroup.value = 350;
        } else if (membership.idBranch === 125
          && fixedTaxGroup.name !== 'student'
          && fixedTaxGroup.name !== 'master'
          && fixedTaxGroup.name !== 'fitness mall'
        ) {
          fixedTaxGroup.value = 205;
        }

      } else {
        const taxPlan = this.fixedTaxPlanFr.find(taxGroup => taxGroup.name === planTitle);
        fixedTaxGroup = taxPlan ? {...taxPlan} : { name: '', value: 185 };
      }

      if (!isAllocated) {
        plans.push({
          id: idBranch,
          name: planTitle,
          extraText: null,
          observation: null,
          taxText: membership.additionalService ? MembershipOfferFeeType[membership.additionalService.name] : 'Taxa de Adesão',
          taxValue: fixedTaxGroup.value,
          parkingText: null,
          plans: [],
        });
      }
    });

    return plans;
  }

  private separateSubPlans(planTitle: string, memberships: EvoMembershipResponseModel[]): SubPlanInfo[] {
    const subPlans: SubPlanInfo[] = [];

    const filteredList = memberships.filter(membership => {
      return (membership.displayName && planTitle === this.removeDescriptionsFromTitle(membership.displayName));
    });

    filteredList.forEach(membership => {
      const { displayName, idMembership, onlineSalesObservations } = membership;
      const subPlanTitle = this.removeCommitmentsFromTitle(displayName);
      const isAllocated = subPlans.find(subPlan => subPlan.shortName === subPlanTitle);

      if (!isAllocated) {
        subPlans.push({
          id: idMembership,
          shortName: subPlanTitle,
          description: onlineSalesObservations
            ? onlineSalesObservations.replace(/<[^>]+>/g, '')
            : '',
          periodicities: [],
        });
      }
    });

    return subPlans;
  }

  private separatePeriodicities(
    subPlanTitle: string,
    memberships: EvoMembershipResponseModel[],
  ): PeriodicityInfo[] {
    const filteredList = memberships.filter(membership => {
      const membershipTitle = this.removeCommitmentsFromTitle(membership.displayName);
      return subPlanTitle === membershipTitle;
    });

    return filteredList.map(membership => {
      /* EVO return annual plans duration as 14, so we calculate de period to have more accuracy. */
      const { nameMembership, value } = membership;
      const period = Object.keys(this.commitmentValues).find(commitment => nameMembership.toLocaleLowerCase().includes(commitment));
      const periodValue = Math.round(value / this.commitmentValues[period]);

      return {
        name: nameMembership,
        months: period === 'mensal' ? 0 : this.commitmentValues[period],
        value: periodValue,
      };
    });
  }

  private sortMemberships(memberships: EvoMembershipResponseModel[]): EvoMembershipResponseModel[] {
    return memberships.sort((a, b) => {
      if (/\d/.test(a.nameMembership) && /\d/.test(b.nameMembership)) {
        return a.nameMembership < b.nameMembership ? -1 : 1;
      }
      return 0;
    });
  }

  mapMembershipsToPlanList(
    memberships: EvoMembershipResponseModel[],
    membersToHideList: MembershipToHideModel[],
    legacyPlans?: PlanInfo[],
    brand: Brand = 'bt',
  ): PlanInfo[] {
    const filteredList = this.filterMembershipList(memberships, membersToHideList);
    return this.separatePlans(filteredList, brand).map(plan => {
      const legacyPlan = legacyPlans.find(item => item.name.toLocaleLowerCase().includes(plan.name));
      const subPlans = this.separateSubPlans(plan.name, filteredList).map(subPlan => {
        const periodicities = this.separatePeriodicities(subPlan.shortName, filteredList);
        return {
          ...subPlan,
          periodicities,
        };
      });

      if (subPlans.length === 1) {
        plan.name = subPlans[0].shortName;
      }

      let isAlt = false;

      if (
        plan.name.toLocaleUpperCase().includes('PLATINUM')
        && plan.id === 100
        && brand === 'bt'
      ) {
        isAlt = true;
      }

      return {
        ...plan,
        parkingText: legacyPlan ? legacyPlan.parkingText : null,
        observation: legacyPlan ? legacyPlan.observation : null,
        plans: subPlans,
        isAlt,
      };
    });
  }
}
