import gql from 'graphql-tag';

export class AuthenticateGraphql {

  public static readonly queryReceptionistLogin = gql`
    query($username: String!, $password: String!) {
      receptionistLogin(username: $username, password: $password) {
        id
        name
        portalName
        slug
        address {
          id
          street
          city {
            id
            name
            state {
              id
              name
              initials
            }
          }
        }
        tag
        searchTags
      }
    }
  `;

  public static readonly queryTeacherLogin = gql`
    query($username: String!, $password: String!) {
      teacherLogin(username: $username, password: $password) {
        id
        name
      }
    }
  `;
}
