import gql from 'graphql-tag';


export interface PeriodicityInfo {
  name: string;
  months: number;
  value: number;
  processed?: boolean;
}

export interface SubPlanInfo {
  selected?: boolean;
  id: number;
  shortName: string;
  description: string;
  periodicities: PeriodicityInfo[];
}

export interface PlanInfo {
  id: number;
  name: string;
  extraText: string;
  taxText: string;
  taxValue: number;
  parkingText: string;
  observation: string;
  plans: SubPlanInfo[];
  isAlt?: boolean;
}

export class PlanPriceListGraphql {

  public static readonly queryListAllGroupsByGymUnit = gql`
    query($gymUnitId: Long!) {
      listAllGroupsByGymUnit(gymUnitId: $gymUnitId) {
        id
        name
        extraText
        observation
        taxText
        taxValue
        parkingText
        plans {
          id
          shortName
          frequency
          description
          periodicities {
            name
            months
            value
          }
        }
      }
    }
  `;
}
