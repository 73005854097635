import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { PhotoGraphql, ResponseError, PhotoUpdate } from '@models';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';

@Injectable()
export class PhotoService {

  constructor(
    private readonly apollo: Apollo,
  ) { }

  public getPhotosByGymId(gymUnitId: number): Observable<PhotoUpdate[]> {
    return this.apollo.query({
      query: PhotoGraphql.queryListPhotosByGymUnit,
      variables: {
        id: gymUnitId
      },
    }).pipe(
      map(JSONUtil.turnApolloMutable('listPhotosByGymUnit')),
    );
  }

  public saveCarrosselPhotos(gymUnitId: number, carrosselPhotos: PhotoUpdate[]) {
    return this.apollo.mutate<PhotoUpdate>(
      {
        mutation: PhotoGraphql.mutationAssociatePhotosToGymUnit,
        variables: {
          gymUnitId,
          photos: carrosselPhotos,
        }
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<ResponseError[]>('associatePhotosToGymUnit')),
      );
  }

}
