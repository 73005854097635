import { Injectable } from '@angular/core';
import { AppConstants } from '@utils/app-constants';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  /*
    Função que retorna um peçado de uma url passada utilizando as '/' como 'corte'
    o parâmetro 'quantity' diz quantas barras serão consideradas e o parametro
    'startPosition' (END, START ou um número) diz qual é o ponto de referencia para o corte
      ex:
        url: www.google.com/imagens/teste/2 startPosition: END quantity: 2
        Saída: teste/2
  */
  getPartialUrl(url: string, startPosition: string | number, quantity: number): string {
    const splittedUrl = url.split('/');
    let result: string[];
    if (startPosition === AppConstants.END) {
      result =  splittedUrl.splice(splittedUrl.length - (quantity), quantity);
    } else if (startPosition === AppConstants.START) {
      result = splittedUrl.splice(0, quantity);
    } else {
      result = splittedUrl.splice(startPosition as number, quantity);
    }
    return result.join('/');
  }

  moveElements<T extends { isSelected?: boolean, displayOrder?: number }>(list: T[], movementDirection: string): T[] {
    const splicedImages = list.map((c, index) => {
      if (c.isSelected) {
        return { c, index };
      }
    }).filter(e => e);
    if (!splicedImages.length) {
      return list;
    }
    const positionOfFirst = splicedImages[0].index;
    const positionOfLast = splicedImages[splicedImages.length - 1].index;
    if (splicedImages.length) {
      if (movementDirection === 'up' && positionOfFirst - 1 >= 0) {
        list = list.filter(image => !image.isSelected);
        list.splice(positionOfFirst - 1, 0, ...splicedImages.map(s => s.c));

      } else if (movementDirection === 'down' && positionOfLast < list.length - 1) {
        list = list.filter(image => !image.isSelected);
        list.splice(positionOfFirst + 1, 0, ...splicedImages.map(s => s.c));
      } else {
        return list;
      }
      list.forEach((c, index) => {
        c.displayOrder = index;
      });
    }
    return list;
  }

  jsonCopy(object: any) {
    return JSON.parse(JSON.stringify(object));
  }


  getDistinctArray(arr, key) {
    const dups = {};
    return arr.filter(el => {
        const hash = el[key].valueOf();
        const isDup = dups[hash];
        dups[hash] = true;
        return !isDup;
    });
  }

}
