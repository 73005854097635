import { Injectable } from '@angular/core';
import { PeriodizationItemUpdate } from '@models/periodization';

@Injectable({
  providedIn: 'root'
})
export class PeriodizationItemsService {

  constructor() { }

  generatePeriodizationItem(periodizationItemDate, periodizationDomainId, periodizationDomainVariableId) {
    return {
      periodizationItemDate,
      periodizationDomainId,
      periodizationDomainVariableId
    } as PeriodizationItemUpdate;
  }
}
