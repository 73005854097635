import { Injectable } from '@angular/core';
import { WorkingPeriod, WorkingPeriodGraphql, WorkingPeriodUpdate } from '@models/working-period';

import { Apollo } from 'apollo-angular';

import { ResponseError, GymUnit } from '@models';
import { GraphqlParser } from '@utils/graphql-parser';
import { JSONUtil } from '@utils/json-util';

import { of, forkJoin } from 'rxjs';
import { map, defaultIfEmpty } from 'rxjs/operators';
import { SortWorkingPeriodsPipe } from '../pipes';

@Injectable()
export class WorkingPeriodService {

  public static weekDays = ['Seg. a Sex.', 'Sábado', 'Domingo', 'Feriados'];

  constructor(
    private readonly apollo: Apollo,
  ) { }

  // QUERYS //
  listWorkingPeriodsByGymUnit(gymUnitId: number) {
    return this.apollo.query<WorkingPeriod[]>({
      query: WorkingPeriodGraphql.queryListWorkingPeriodsByGymUnit,
      variables: {
        id: gymUnitId
      },
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<WorkingPeriod[]>('listWorkingPeriodsByGymUnit')),
      map(result => {
        const sortWorkingPeriodsPipe = new SortWorkingPeriodsPipe();
        return sortWorkingPeriodsPipe.transform(result);
      })
    );
  }

  // PROCESS TO MUTATION
  preProcessToInsertOrUpdate(workingPeriods: WorkingPeriod[], toDeleteWorkingPeriod: WorkingPeriod[]) {
    const workingPeriodUpdate: WorkingPeriodUpdate[] = this.formatToWorkingPeriodUpdate(workingPeriods);

    const toCreate = this.saveAll(workingPeriodUpdate.filter(w => w.id === 0));
    const toUpdate = this.updateAll(workingPeriodUpdate.filter(w => w.id > 0));
    const toDelete = this.deleteAll(toDeleteWorkingPeriod);

    return forkJoin([toCreate, toUpdate, toDelete]);
  }

  private formatToWorkingPeriodUpdate(workingPeriods: WorkingPeriod[]): WorkingPeriodUpdate[] {
    const wokingPeriodUpdate =
      workingPeriods.map(period => {
        return {
          id: period.id,
          dayOfTheWeek: period.dayOfTheWeek,
          initialTimeOne: period.initialTimeOne || null,
          finalTimeOne: period.finalTimeOne || null,
          initialTimeTwo: period.initialTimeTwo || null,
          finalTimeTwo: period.finalTimeTwo || null,

          gymUnit: { id: period.gymUnit.id } as GymUnit
        };
      });

    return wokingPeriodUpdate;
  }

  // MUTATION //
  saveAll(workingPeriods: WorkingPeriodUpdate[]) {
    if (!workingPeriods.length) {
      return of().pipe(defaultIfEmpty('Observable.of() Empty!'));
    }

    return this.apollo.mutate<WorkingPeriodUpdate>(
      {
        mutation: GraphqlParser.parseToCreateMutation(workingPeriods, { method: 'createWorkingPeriod', object: 'workingPeriod'}),
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<ResponseError[]>()),
      );
  }

  updateAll(workingPeriods: WorkingPeriodUpdate[]) {
    if (!workingPeriods.length) {
      return of().pipe(defaultIfEmpty('Observable.of() Empty!'));
    }

    return this.apollo.mutate<WorkingPeriodUpdate>(
      {
        mutation: GraphqlParser.parseToUpdateMutation(workingPeriods, { method: 'updateWorkingPeriod', object: 'workingPeriod'}),
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<ResponseError[]>()),
      );
  }

  deleteAll(workingPeriods: WorkingPeriod[]) {
    if (!workingPeriods.length) {
      return of().pipe(defaultIfEmpty('Observable.of() Empty!'));
    }

    return this.apollo.mutate<WorkingPeriodUpdate>(
      {
        mutation: GraphqlParser.parseToDeleteMutation(workingPeriods, { method: 'deleteWorkingPeriod' }),
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<ResponseError[]>()),
      );
  }

  // UTILS
  public isStandardPeriod(period: string) {
    return WorkingPeriodService.weekDays.includes(period);
  }

}
