<div class="main-container" fxLayout="column" fxLayoutAlign="space-between">
  <div
    fxLayout="column"
    fxLayoutAlign="space-evenly center"
    class="upload-box"
    appFileDrop
    (filesDropped)="handleDrop($event)"
    (filesHovered)="dropzoneState($event)"
    [ngClass]="{'active': dropzoneActive}"
    (click)="selectFileWihtClick()">
    <div class="B1-LatoRegular-C4 upload-box-text" [innerHTML]="dragText || 'Clique para selecionar<br>ou arraste as imagens'"></div>
    <img class="image" [src]="dragImage || './assets/icon_custom/36-p-camera-c-4.svg'">
  </div>
  <div class="B1-LatoRegular-C4 mt-6 text-center" [innerHTML]="dropAreaAuxText"></div>
</div>
<input type="file" (change)="selectFile($event)" class="file-input" [multiple]="multiple" #someInput>
