import gql from 'graphql-tag';
import { RoleBackoffice } from './role-backoffice';
import { GymUnit } from './gym-unit';

export interface UserPermission {
  systemManagerRole: RoleBackoffice;
  gymUnits: GymUnit[];
}

export class UserPermissionGraphql {

  public static readonly queryGetEligibleUsersByRole = gql`
    query($roleId: Long!) {
      listEligibleUsersByRole(roleId: $roleId) {
        id
        username
      }
    }
  `;

  public static readonly queryGetUsersAndPermissions = gql`
    query {
      listUsersAndPermissions {
        systemManagerRole {
          id
          name
          description
          users {
            id
            username
          }
        }
        gymUnits {
          id
          name
          roles {
            id
            name
            description
            users {
              id
              username
            }
          }
        }
      }
    }
  `;

  public static readonly mutationAssociateUsersToRoleAndGymUnits = gql`
    mutation($usersIds: [Long!], $roleId: Long!, $gymUnitId: Long) {
      associateUsersToRoleAndGymUnits(usersIds: $usersIds, roleId: $roleId, gymUnitId: $gymUnitId) {
        id
        errors {
          field
          message
        }
      }
    }
  `;

}
