import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ResponseError, PromotionGraphql, Promotion, PromotionPagedResult } from '@models';
import { JSONUtil } from '@utils/json-util';
import { GraphqlParser } from '@utils/graphql-parser';
import { map, defaultIfEmpty } from 'rxjs/operators';

@Injectable()
export class PromotionService {
  constructor(private readonly apollo: Apollo) {}

  public getPromotion(id: number): Observable<Promotion> {
    return this.apollo
      .query({
        query: PromotionGraphql.queryPromotion,
        variables: {
          id,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable('promotion')));
  }

  public listPromotions(max: number, offset: number): Observable<PromotionPagedResult> {
    return this.apollo
      .query({
        query: PromotionGraphql.queryListPromotion,
        variables: {
          max,
          offset,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable('listPromotions')));
  }

  // PROCESS TO MUTATION
  preProcessToSave(promotions: Promotion[], toDeletePromotions: Promotion[]) {
    const promotionsToCreate: Promotion[] = this.formatToPromotion(
      promotions.filter(w => !w.id).reverse(),
    );
    const promotionsToUpdate: Promotion[] = this.formatToPromotion(
      promotions.filter(w => !!w.id && w.dirty),
    );

    const toCreate = this.createAllPromotion(promotionsToCreate);
    const toUpdate = this.updateAllPromotion(promotionsToUpdate);
    const toDelete = this.deleteAllPromotion(toDeletePromotions);

    return zip(toDelete, toUpdate, toCreate);
  }

  private formatToPromotion(promotions: Promotion[]): Promotion[] {
    const promotionsFormatted = promotions.map(p => {
      return {
        id: p.id,
        thumbnailText: p.thumbnailText,
        purchaseTitle: p.purchaseTitle,
        purchaseText: p.purchaseText,
        initialDate: p.initialDate,
        finalDate: p.finalDate,
        gymUnities: p.gymUnities,
      };
    });

    return promotionsFormatted;
  }

  public createAllPromotion(promotions: Promotion[]) {
    if (!promotions.length) {
      return of().pipe(defaultIfEmpty('Observable.of() Empty!'));
    }

    return this.apollo
      .mutate<Promotion>({
        mutation: PromotionGraphql.parseToCreateMutation(promotions),
      })
      .pipe(map(JSONUtil.turnApolloMutable<ResponseError[]>()));
  }

  public updateAllPromotion(promotions: Promotion[]) {
    if (!promotions.length) {
      return of().pipe(defaultIfEmpty('Observable.of() Empty!'));
    }

    return this.apollo
      .mutate<Promotion>({
        mutation: PromotionGraphql.parseToUpdateMutation(promotions),
      })
      .pipe(map(JSONUtil.turnApolloMutable<ResponseError[]>()));
  }

  public deleteAllPromotion(promotions: Promotion[]) {
    if (!promotions.length) {
      return of().pipe(defaultIfEmpty('Observable.of() Empty!'));
    }

    return this.apollo
      .mutate<Promotion>({
        mutation: GraphqlParser.parseToDeleteMutation(promotions, {
          method: 'deletePromotion',
        }),
      })
      .pipe(map(JSONUtil.turnApolloMutable<ResponseError[]>()));
  }
}
