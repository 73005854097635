<div fxLayout="row">
  <div fxFlex class="tab-item"
    *ngFor="let tab of tabs; let index = index"
    [class.active]="activeTab == tab"
    [style.width.%]="100/tabs.length"
    (click)="activeTabChange.emit(tab)">
    <a id="tab-{{name}}-{{index}}" class="tab h-100"
      [class.has-icon]="tab.icon">
      <img class="icon" *ngIf="tab.icon" [src]="tab.icon">
      <div>{{ tab[textKey] }}</div>
    </a>
  </div>
</div>
