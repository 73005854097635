import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackingErrorsService {

  constructor() { }
  // Serviço utilizado para tratar mensagns de erro que vem do backend quando a resposta é 200
  trackingErrors(object: any) {
    let message = null;
    object.forEach(obj => {
      Object.values(obj).forEach((value: any) => {
        if (value.errors && value.errors.length > 0) {
          message = value.errors[0].message;
        }
      });
    });
    return message;
  }
}
