import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { MaskedInputDirective, FileDropDirective, LinkDirective, MaxLengthValidatorDirective, LazyCreateDirective } from '@directives';
import { SelectGymModalComponent } from './components/sided-menu-generic-modal/select-gym-modal/select-gym-modal.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { TagInputModule } from 'ngx-chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DropAreaComponent } from './components/drop-area/drop-area.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SortWorkingPeriodsPipe, FilterPipe, BrandPipe, CapitalizePipe } from 'app/core/pipes';
import { SelectIconModalComponent } from './components/select-icon-modal/select-icon-modal.component';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { CustomDateAdapter } from '@utils/custom-date-adapter';
import { ChooseGymsModalComponent } from './components/sided-menu-generic-modal/choose-gyms-modal/choose-gyms-modal.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ViewDataListModalComponent } from './components/view-data-list-modal/view-data-list-modal.component';
import { SidedMenuGenericModalComponent } from './components/sided-menu-generic-modal/sided-menu-generic-modal.component';
import { MultipleSelectModalComponent } from './components/sided-menu-generic-modal/multiple-select-modal/multiple-select-modal.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { ActivePipe } from 'app/core/pipes/active.pipe';
import { TeachersEditModalComponent } from 'app/modules/internal/pages/teachers/teachers-edit-modal/teachers-edit-modal.component';
import {
  AppBannersPreviewModalComponent
} from 'app/modules/internal/pages/app-banners/app-banners-preview-modal/app-banners-preview-modal.component';
import {
  AppBannersEditModalComponent
} from 'app/modules/internal/pages/app-banners/app-banners-edit-modal/app-banners-edit-modal.component';
import { BannersPreviewModalComponent } from 'app/modules/internal/pages/banners/banners-preview-modal/banners-preview-modal.component';
import {
  BannersEditModalComponent
} from 'app/modules/internal/pages/banners/banners-edit-modal/banners-edit-modal.component';
import {
  ConvenienceEditModalComponent
} from 'app/modules/internal/pages/conveniences/convenience-edit-modal/convenience-edit-modal.component';
import {
  InfoServicesEditModalComponent
} from 'app/modules/internal/pages/info-services/info-services-edit-modal/info-services-edit-modal.component';
import {
  InformativesViewEditModalComponent
} from 'app/modules/internal/pages/informatives/informatives-view-edit-modal/informatives-view-edit-modal.component';
import {
  PromotionsEditModalComponent
} from 'app/modules/internal/pages/promotions/promotions-edit-modal/promotions-edit-modal.component';
import {
  UserPermissionsEditModalComponent
} from 'app/modules/internal/pages/user-permissions/user-permissions-edit-modal/user-permissions-edit-modal.component';
import { PeriodizationFormComponent } from 'app/modules/internal/pages/periodization/periodization-form/periodization-form.component';
import { SelectTeacherModalComponent, ShowContactsModalComponent, AuditRoomModalComponent } from '@sharedcomponents';
import { CheckboxModule } from '@sharedcomponents';

const globalEntryComponents = [
  AppBannersEditModalComponent,
  AppBannersPreviewModalComponent,
  BannersEditModalComponent,
  BannersPreviewModalComponent,
  ChooseGymsModalComponent,
  ConvenienceEditModalComponent,
  GenericModalComponent,
  InformativesViewEditModalComponent,
  InfoServicesEditModalComponent,
  MultipleSelectModalComponent,
  PeriodizationFormComponent,
  PromotionsEditModalComponent,
  SelectGymModalComponent,
  SelectIconModalComponent,
  SelectTeacherModalComponent,
  ShowContactsModalComponent,
  AuditRoomModalComponent,
  SidedMenuGenericModalComponent,
  TeachersEditModalComponent,
  UserPermissionsEditModalComponent,
  ViewDataListModalComponent,
];

const globalComponents = [
  ChooseGymsModalComponent,
  DropAreaComponent,
  ErrorMessageComponent,
  FileDropComponent,
  GenericModalComponent,
  ListFilterComponent,
  MultipleSelectModalComponent,
  SelectGymModalComponent,
  SelectIconModalComponent,
  SelectTeacherModalComponent,
  ShowContactsModalComponent,
  SidedMenuGenericModalComponent,
  TabsComponent,
  ViewDataListModalComponent,
];

const globalDirectives = [
  FileDropDirective,
  LinkDirective,
  MaskedInputDirective,
  MaxLengthValidatorDirective,
  LazyCreateDirective,
];


const globalPipes = [
  BrandPipe,
  FilterPipe,
  SortWorkingPeriodsPipe,
  ActivePipe,
  CapitalizePipe,
];

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

TagInputModule.withDefaults({
  tagInput: {
    placeholder: 'Insira uma nova tag',
    secondaryPlaceholder: 'Insira uma nova tag',
  },
});

@NgModule({
  declarations: [
    ...globalComponents,
    ...globalDirectives,
    ...globalPipes,
    AuditRoomModalComponent,
  ],
  imports: [
    FlexLayoutModule,
    NgSelectModule,
    TagInputModule,
    DragDropModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CheckboxModule,
  ],
  providers: [
    MatDatepickerModule,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
  exports: [
    ChooseGymsModalComponent,
    DropAreaComponent,
    ErrorMessageComponent,
    FileDropComponent,
    GenericModalComponent,
    ListFilterComponent,
    MultipleSelectModalComponent,
    SelectGymModalComponent,
    SelectIconModalComponent,
    SelectTeacherModalComponent,
    ShowContactsModalComponent,
    SidedMenuGenericModalComponent,
    TabsComponent,
    ViewDataListModalComponent,
    FileDropDirective,
    LinkDirective,
    MaskedInputDirective,
    MaxLengthValidatorDirective,
    LazyCreateDirective,
    FlexLayoutModule,
    NgSelectModule,
    TagInputModule,
    DragDropModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrandPipe,
    FilterPipe,
    SortWorkingPeriodsPipe,
    ActivePipe,
    CapitalizePipe,
    CheckboxModule,
  ],
  entryComponents: [
    ...globalEntryComponents
  ],
})
export class SharedModule {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    private ngSelectConfig: NgSelectConfig,
  ) {
    this.dateAdapter.setLocale('pt-BR');
    this.ngSelectConfig.notFoundText = 'Nenhum item encontrado';
  }
}
