import { forkJoin, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { EvoBaseParamsModel, EvoGetAllPaginationRecursiveFn } from '@models';


/**
 * Make a recursive request to EVO services that has pagination.
 * For each request with a value, a next request will be make using the function given in `fn` param.
 *
 * @param params - General query params used in EVO services.
 * @param fn - Function wich `this` operator is use.
 * @returns Returns response with all paginations response merge in one array.
 */
export function evoGetAllPagination<T>(params: EvoBaseParamsModel, fn: EvoGetAllPaginationRecursiveFn<T>): MonoTypeOperatorFunction<T> {
  return (source: Observable<any>): Observable<T> => {
    return source.pipe(
      tap(response => params.skip = (+params.skip + response.length).toString()),
      mergeMap(response => !!response.length ? forkJoin([of(response), fn(params)]) : of(response)),
      map(response => response.flat()),
    );
  };
}
