import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PlanDetailsGraphql, GymUnitGroupPlan } from '@models/plan-detail';
import { Observable } from 'rxjs';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { StringUtil } from '@utils/string.util';
import { ResponseError } from '@models';

@Injectable({
  providedIn: 'root',
})
export class PlanDetailsService {
  constructor(private readonly apollo: Apollo) {}

  public listPlans(): Observable<GymUnitGroupPlan[]> {
    return this.apollo
      .query({
        query: PlanDetailsGraphql.queryListGymUnitGroupPlans,
      })
      .pipe(
        map(JSONUtil.turnApolloMutable('listGymUnitGroupPlans')),
        map((listGymUnitGroupPlans: any) =>
          listGymUnitGroupPlans
            .map(v => {
              return {
                plan: v.gymUnitGroupPlan.plan,
                gymUnities: v.gymUnities,
              };
            })
            .sort((a, b) => {
              return StringUtil.strcmp(a.plan.shortName, b.plan.shortName);
            }),
        ),
      );
  }

  public save(gymUnitGroupPlan): Observable<ResponseError> {
    const {
      planId,
      shortDescription,
      longDescription,
      availableActivities,
      portalName,
    } = gymUnitGroupPlan;
    const gymUnitIds = gymUnitGroupPlan.gymUnities.map(gym => gym.id);
    return this.apollo
      .mutate<any>({
        mutation:
          PlanDetailsGraphql.mutationUpdateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities,
        variables: {
          planId,
          gymUnitIds,
          shortDescription,
          longDescription,
          availableActivities,
          portalName,
        },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<ResponseError>(
            'updateBatchGymUnitGroupPlanDescriptionsNameAndAvailableActivities',
          ),
        ),
      );
  }
}
