import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Informative } from '@models';

@Component({
  selector: 'app-informatives-view-edit-modal',
  templateUrl: './informatives-view-edit-modal.component.html',
  styleUrls: ['./informatives-view-edit-modal.component.scss'],
})
export class InformativesViewEditModalComponent {

  constructor(
    public dialogRef: MatDialogRef<InformativesViewEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  closeModal(): void {
    this.dialogRef.close();
  }

  editInformative(informative: Informative) {
    this.dialogRef.close(informative);
  }
}
