import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';

import { ApolloConfigModule } from '@utils/apollo-config/apollo-config.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './modules/login/login.component';

import { BlockUiInterceptor } from './core/interceptors/block-ui.interceptor';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

import { AuthenticationService } from '@services/authentication.service';
import { EndpointsService } from '@services/endpoints.service';
import { StorageService } from '@services/storage.service';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthenticationGuard } from '@guards/authentication.guard';
import { BrandService } from '@services/brand.service';
import { GymService } from '@services/gym.service';
import { LoginGuard } from '@guards/login.guard';
import { AuthenticationScheduleGuard } from '@guards/authentication-schedule.guard';
import { AuthenticationAppointmentLiveClassGuard } from '@guards/authentication-appointment-live-class.guard';
import {
  EntryScheduleReportModalComponent
} from './modules/schedules/entry-schedule/entry-schedule-report-modal/entry-schedule-report-modal.component';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '@env/environment';

registerLocaleData(ptBr);

const toastrConfig = {
  positionClass: 'inline',
  timeOut: 10000,
  preventDuplicates: true,
  progressBar: true,
  closeButton: true,
  tapToDismiss: false,
};

export function createApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({uri: environment.baseUrl}),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  declarations: [ AppComponent, LoginComponent ],
  imports: [
    ApolloConfigModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    SharedModule,
    ToastrModule.forRoot(toastrConfig),
    ToastContainerModule,
    BrowserAnimationsModule,
    BlockUIModule.forRoot({
      message: 'Carregando...',
    }),
  ],
  providers: [
    AuthenticationService,
    EndpointsService,
    StorageService,
    AlertMessageService,
    AuthenticationGuard,
    AuthenticationScheduleGuard,
    AuthenticationAppointmentLiveClassGuard,
    BrandService,
    GymService,
    LoginGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: BlockUiInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    EntryScheduleReportModalComponent,
  ],
})
export class AppModule { }
