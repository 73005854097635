import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-audit-room-modal',
  templateUrl: './audit-room-modal.component.html',
  styleUrls: ['./audit-room-modal.component.scss']
})
export class AuditRoomModalComponent<T> {

  keyNames = {
    id: 'ID',
    registrant_id: 'ID Registrante',
    user_id: 'User ID',
    user_name: 'Usuário',
    device: 'Dispositivo',
    ip_address: 'Endereço de IP',
    location: 'Localização',
    network_type: 'Tipo de Rede',
    microphone: 'Microfone',
    speaker: 'Auto-falante',
    camera: 'Câmera',
    data_center: 'Data Center',
    connection_type: 'Tipo de Conexão',
    join_time: 'Entrada na Chamada',
    leave_time: 'Saída da Chamada',
    share_application: 'Compartilhamento de Aplicação',
    share_desktop: 'Compartilhamento de Desktop',
    share_whiteboard: 'Compartilhamento de Whiteboard',
    recording: 'Gravando',
    pc_name: 'Nome do Computador',
    domain: 'Domínio',
    mac_addr: 'Mac Address',
    harddisk_id: 'ID do Disco Rígido',
    version: 'Versão',
    in_room_participants: 'Número de Participantes via ZoomRoom',
    leave_reason: 'Motivo da Saída',
    email: 'E-mail',
    audio_quality: 'Qualidade do Áudio',
    video_quality: 'Qualidade do Video',
    screen_share_quality: 'Qualidade do Compartilhamento de Tela',
  };

  isDateField = [
    'leave_time',
    'join_time'
  ];

  isBooleanField = [
    'share_application',
    'recording',
    'share_whiteboard',
    'share_desktop',
  ];

  preserveOriginalOrder = (a: any, b: any) => {
    return 0;
  }


  constructor(
    public dialogRef: MatDialogRef<AuditRoomModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  closeModal(icon?: T): void {
    this.dialogRef.close(icon || false);
  }

}
