import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserBackoffice, ResponseError, UserRegister } from '@models';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { UserPermissionGraphql, UserPermission } from '@models/user-permission';
import { JSONUtil } from '@utils/json-util';
import { SurEndpoints } from '@utils/app-endpoints';
import { EndpointsService } from './endpoints.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {
  constructor(
    private apollo: Apollo,
    private endpointsService: EndpointsService,
    private http: HttpClient,
  ) {}

  public getUsersAndPermissions(): Observable<UserPermission> {
    return this.apollo.query<UserPermission>({
      query: UserPermissionGraphql.queryGetUsersAndPermissions,
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<UserPermission>('listUsersAndPermissions'))
    );
  }

  public listEligibleUsersByRole(roleId: number): Observable<UserBackoffice[]> {
    return this.apollo.query<UserBackoffice[]>({
      query: UserPermissionGraphql.queryGetEligibleUsersByRole,
      variables: {
        roleId
      }
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<UserBackoffice[]>('listEligibleUsersByRole'))
    );
  }

  public associateUsersToRoleAndGymUnits(usersIds: number[], roleId: number, gymUnitId: number): Observable<ResponseError[]> {
    return this.apollo.mutate({
      mutation: UserPermissionGraphql.mutationAssociateUsersToRoleAndGymUnits,
      variables: {
        usersIds,
        roleId,
        gymUnitId
      }
    })
    .pipe(
      map(JSONUtil.turnApolloMutable<ResponseError[]>('associateUsersToRoleAndGymUnits'))
    );
  }

  public registerUser(body: UserRegister) {
    const url = this.endpointsService.endpoints(SurEndpoints.Register);
    return this.http.post(url, body);
  }

}
