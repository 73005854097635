import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { Apollo, ApolloModule } from 'apollo-angular';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { setContext } from 'apollo-link-context';
import { DefaultOptions } from 'apollo-client/ApolloClient';

import {
  AlertMessageService,
  BlockUiService,
  AuthenticationService,
  StorageService,
} from '@services';

import { sur, SurEndpoints } from '@utils/app-endpoints';
import { linkError } from '@utils/apollo-config/error.middleware';

import { NO_BLOCK_UI_HTTP_HEADER } from '@models';
import { StringUtil } from '@utils/string.util';
import { environment } from '@env/environment';
import { AppConstants } from '@utils/app-constants';
import { Router, NavigationEnd } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    HttpLinkModule,
    HttpClientModule,
    ApolloModule,
  ],
  exports: [ApolloModule],
  providers: [],
  entryComponents: []
})
export class ApolloConfigModule {

  hasAlreadyCreateApollo = false;

  static forRoot(): ModuleWithProviders<ApolloConfigModule> {
    return {
      ngModule: ApolloConfigModule,
      providers: [],
    };
  }

  constructor(
    public readonly apollo: Apollo,
    public readonly httpLink: HttpLink,
    public readonly authService: AuthenticationService,
    public readonly alertMessageService: AlertMessageService,
    public readonly blockUiService: BlockUiService,
    public readonly location: Location,
    private readonly storageService: StorageService,
    private readonly router: Router,
  ) {

    const http = httpLink.create({uri: sur(SurEndpoints.Url)});

    const noBlockUiLink = setContext((_, ctx) => {
      if (ctx.noBlockUi) {
        const headers = (ctx.headers || new HttpHeaders());

        return {
          headers: headers.set(NO_BLOCK_UI_HTTP_HEADER, 'NO-BLOCK-UI'),
        };
      }
    });

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    };

    apollo.create({
      link: ApolloLink.from([
        linkError(
          this.authService,
          this.alertMessageService,
          this.blockUiService,
        ),
        noBlockUiLink,
        http,
      ]),
      cache: new InMemoryCache({
        dataIdFromObject: object => {
          return StringUtil.hashCode(JSON.stringify(object)).toString();
        },

      }),
      defaultOptions,
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !this.hasAlreadyCreateApollo) {

        this.hasAlreadyCreateApollo = true;

        const xAuthorization = this.getXAutorizathionKey(event);

        const interceptor = new ApolloLink((operation, forward) => {
          operation.setContext({
            headers: {
              'X-Authorization': this.storageService.get(xAuthorization),
            },
          });

          return forward(operation);
        });

        apollo.create({
          link: concat(interceptor, httpPortal),
          cache: new InMemoryCache(),
          defaultOptions,
        }, 'portal');
      }
    });

    const httpPortal = httpLink.create({
      uri: environment.portal + '/graphql',
    });

  }

  private getXAutorizathionKey(event: NavigationEnd): string {
    const splittedUrl = event.url.split('/');
    let xAuthorization = '';
    if (splittedUrl.some(urlPart => urlPart === 'aulas-online')) {
      xAuthorization = AppConstants.STOR_PORTAL_TOKEN_APPOINTMENT_LIVE_CLASS;
    } else if (splittedUrl.some(urlPart => urlPart === 'agendamentos')) {
      xAuthorization = AppConstants.STOR_PORTAL_TOKEN_SCHEDULE;
    } else {
      xAuthorization = AppConstants.STOR_PORTAL_TOKEN;
    }
    return xAuthorization;
  }
}
