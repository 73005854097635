import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtil } from '@utils/string.util';
import { ViewDataList } from '@models/dialog-modal';

@Component({
  selector: 'app-view-data-list-modal',
  templateUrl: './view-data-list-modal.component.html',
  styleUrls: ['./view-data-list-modal.component.scss']
})
export class ViewDataListModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ViewDataListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewDataList,
  ) { }

  ngOnInit() {
    if (this.data.hasToOrder) {
      this.data.list.sort((a, b) => {
        return StringUtil.strcmp(a, b);
      });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
