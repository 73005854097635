import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EvoGymMigratingRequest, EvoGymMigrationResponse } from '@models';
import { serviceBack, ServiceBackEndpoints } from '@utils/app-endpoints';

@Injectable()
export class EvoGeneralService {
  constructor( private readonly http: HttpClient ) {}

  checkMigratingGym(unit: number): Observable<EvoGymMigrationResponse> {
    const evoGymRequest: EvoGymMigratingRequest = { unit };

    return this.http.post<EvoGymMigrationResponse>(serviceBack(ServiceBackEndpoints.EvoCheckMigratingGym), evoGymRequest);
  }
}
