import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { AppConstants } from '@utils/app-constants';
import { GenericModalComponent } from '@sharedcomponents/generic-modal/generic-modal.component';
import { UploadService, AlertMessageService, BlockUiService, DialogService } from '@services';
import { environment } from '@env/environment';
import { Observable } from 'apollo-link';
import { AlertMessage, MessageMap } from '@models';

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDropComponent implements OnInit, OnDestroy, AfterViewInit {

  dropzoneActive = false;
  standardWidth: number;
  standardHeigth: number;

  purpose: string;
  @Input() isToViewOnly = false;
  @Input() disabledComponent = false;
  @Input() file: any;
  @Input() bucketPath: string;
  @Input() gymSlug: string;

  @Input() dragText: string;
  @Input() dragImage: string;
  @Input() set filePurpose(value: string) {
    this.purpose = value;
    if (value === 'banner') {
      this.standardWidth = AppConstants.BANNER_IMAGE.WIDTH;
      this.standardHeigth = AppConstants.BANNER_IMAGE.HEIGHT;
    } else if (value === 'header' || value === 'thumbnail') {
      this.standardWidth = AppConstants.THUMBNAIL_IMAGE.WIDTH;
      this.standardHeigth = AppConstants.THUMBNAIL_IMAGE.HEIGHT;
    } else if (value === 'app_banner') {
      this.standardWidth = AppConstants.APP_BANNER_IMAGE.WIDTH;
      this.standardHeigth = AppConstants.APP_BANNER_IMAGE.HEIGHT;
    } else if (value === 'informative') {
      this.standardWidth = AppConstants.INFORMATIVE_IMAGE.WIDTH;
      this.standardHeigth = AppConstants.INFORMATIVE_IMAGE.HEIGHT;
    }
  }

  private eventsSubscription: any;
  @Input() events: Observable<void>;
  @Input() invalid = false;

  @Input() reset: EventEmitter<void>;
  @Output() fileUrlEmitter: EventEmitter<string> = new EventEmitter();
  @Output() isLoadingEmitter: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('someInput') someInput: ElementRef;
  @ViewChild('uploadBox') uploadBox: ElementRef;
  @ViewChild('containerFileDrop') containerFileDrop: ElementRef;

  private widthUploadBox = '100%';
  private heightUploadBox = 'auto';
  private orderError = 2;
  private orderProperty = 3;
  private orderImage = 4;
  private isHorizontal = true;

  public hasPdfFile = false;
  public isLoading = false;
  public bucketName: string;

  color = 'primary';
  mode = 'indeterminate';
  value = 50;

  constructor(
    private uploadService: UploadService,
    private dialogService: DialogService,
    private blockUiService: BlockUiService,
    private alertMessageService: AlertMessageService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.events) {
      this.eventsSubscription = this.events.subscribe(() => this.selectFileWihtClick());
    }
    if (this.reset) {
      this.reset.subscribe(() => this.hasPdfFile = false);
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if ((this.containerFileDrop.nativeElement.offsetWidth < 300 || window.innerWidth < 600) && this.uploadBox) {
      this.heightUploadBox = `${this.standardHeigth * (this.uploadBox.nativeElement.offsetWidth / this.standardWidth)}px`;
      this.isHorizontal = false;
    } else if (!this.isToViewOnly) {
      const scale = 0.1333333;
      this.heightUploadBox = `${this.standardHeigth * scale}px`;
      this.widthUploadBox = `${this.standardWidth * scale}px`;
      this.orderImage = 2;
      this.orderError = 3;
      this.orderProperty = 4;
      this.isHorizontal = true;
    }

    if (this.purpose === 'terms') {
      this.heightUploadBox = '160px';
    }
    this.cd.detectChanges();
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  public handleDrop(fileList: FileList) {
    if (this.disabledComponent) {
      return;
    }
    this.isLoadingEmitter.emit(this.isLoading);
    if (fileList.length > 1) {
      const data = {
        modalTitle: 'MÚLTIPLOS ARQUIVOS SELECIONADOS',
        modalContent: `Selecione apenas um arquivo para enviar.`,
        pButtonText: 'CONTINUAR'
      };
      this.openAlertModal(data);
      return;
    }
    const file = fileList[0];
    if (file) {
      this.isLoading = true;
      // verifica primeiramente o tipo de arquivo
      if (this.purpose === 'header' || this.purpose === 'thumbnail'
        || this.purpose === 'banner' || this.purpose === 'app_banner'
        || this.purpose === 'informative') {
        this.bucketName = 'images';
        this.verifyImages(file);
      } else if (this.purpose === 'terms') {
        this.verifyPdf(file);
      } else {
        this.isLoading = false;
      }
      this.calculateSizeBox();
    }
  }

  public doUpload(url: string, file) {
    this.uploadService.uploadFile([file], `${this.bucketPath}`, false, this.bucketName, this.gymSlug).subscribe(
      res => {
        this.fileUrlEmitter.emit(`${url}${res[0]}`);
        this.invalid = false;
        this.isLoading = false;
        this.isLoadingEmitter.emit(this.isLoading);
        if (this.purpose === 'terms') {
          this.hasPdfFile = true;
        }
      },
      () => {
        this.isLoading = false;
        this.isLoadingEmitter.emit(this.isLoading);
        this.blockUiService.decrement();
        this.alertMessageService.showToastr(
          AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET)
        );
      }
    );
  }

  public verifyImages(file): boolean {
    if (file.type !== AppConstants.IMAGE_PNG && file.type !== AppConstants.IMAGE_JPG) {
      const data = {
        modalTitle: 'TIPO DE ARQUIVO INVÁLIDO',
        modalContent: `O arquivo "${file.name}" enviado é inválido. Envie um arquivo de imagem em formato JPEG, JPG ou PNG.`,
        pButtonText: 'CONTINUAR'
      };
      this.openAlertModal(data);
      this.isLoading = false;
      return;
    }
    // depois tranforma o arquivo em imagem para verificação de dimensões
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onerror = () => {
      const data = {
        modalTitle: 'ERRO NO ARQUIVO',
        modalContent: `Não foi possível abrir o arquivo "${file.name}". Envie um arquivo de imagem em formato JPEG, JPG ou PNG.`,
        pButtonText: 'CONTINUAR'
      };
      this.openAlertModal(data);
      this.isLoading = false;
      return;
    };
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      const data = {
        modalTitle: 'DIMENSÕES DA IMAGEM INVÁLIDAS',
        modalContent: `A imagem "${file.name}" tem ${width}x${height}px e o tamanho permitido é
        ${this.standardWidth}x${this.standardHeigth}px.`,
        pButtonText: 'CONTINUAR'
      };

      if (width !== this.standardWidth || height !== this.standardHeigth) {
        this.openAlertModal(data);
        this.isLoading = false;
        return;
      }
      if (!this.disabledComponent) {
        this.doUpload(`${environment.s3buckets.images}/`, file);
      }
    };
  }

  public verifyPdf(file) {
    const data = {
      modalTitle: 'TIPO DE ARQUIVO INVÁLIDO',
      modalContent: `O arquivo "${file.name}" enviado é inválido. Envie um arquivo em formato PDF.`,
      pButtonText: 'CONTINUAR',
    };

    if (file.type !== AppConstants.PDF) {
      this.isLoading = false;
      this.openAlertModal(data);
      return;
    }

    const reader = new FileReader();
    reader.onerror = () => {
      this.openAlertModal(data);
      this.isLoading = false;
      return;
    };
    reader.onload = () => {
      const dataPdf = reader.result.toString().substr(0, 8);
      const regex = new RegExp('%PDF-(1\.[0-7]|2\.0)');
      if (!dataPdf.match(regex)) {
        this.isLoading = false;
        this.openAlertModal(data);
        return;
      }
      this.bucketName = 'terms';
      this.doUpload('', file);
    };
    reader.readAsText(file);
  }

  private openAlertModal(data) {
    this.dialogService.openDialog(GenericModalComponent, {
      width: '796px',
      data
    });
    this.fileUrlEmitter.emit(null);
    this.isLoadingEmitter.emit(this.isLoading);
  }

  selectFile(event) {
    this.handleDrop(event.target.files);
  }

  selectFileWihtClick() {
    if (!this.disabledComponent) {
      this.someInput.nativeElement.click();
    }
  }

  private calculateSizeBox(): any {
    return {
      width: this.widthUploadBox,
      height: this.heightUploadBox
    };
  }
}
