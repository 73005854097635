export * from './alert-message.service';
export * from './appointment-live-class.service';
export * from './authentication.service';
export * from './block-ui.service';
export * from './block-ui.service';
export * from './brand.service';
export * from './convenience.service';
export * from './dialog.service';
export * from './dirty-check.service';
export * from './endpoints.service';
export * from './evo';
export * from './gym.service';
export * from './info-service.service';
export * from './page-header.service';
export * from './periodization.service';
export * from './photo.service';
export * from './plan-details.service';
export * from './plan-price-list.service';
export * from './promotion.service';
export * from './social-media.service';
export * from './storage.service';
export * from './tag.service';
export * from './tracking-errors.service';
export * from './upload.service';
export * from './user-permissions.service';
export * from './utils.service';
export * from './working-period.service';

import { AppointmentLiveClassService } from './appointment-live-class.service';
import { BlockUiService } from './block-ui.service';
import { ConvenienceService } from './convenience.service';
import { DialogService } from './dialog.service';
import { DirtyCheckService } from './dirty-check.service';
import { InfoServiceService } from './info-service.service';
import { PageHeaderService } from './page-header.service';
import { PeriodizationItemsService } from './periodization-items.service';
import { PeriodizationService } from './periodization.service';
import { PhotoService } from './photo.service';
import { PlanDetailsService } from './plan-details.service';
import { PlanPriceListService } from './plan-price-list.service';
import { PromotionService } from './promotion.service';
import { SocialMediaService } from './social-media.service';
import { TagService } from './tag.service';
import { UploadService } from './upload.service';
import { UserPermissionsService } from './user-permissions.service';
import { UtilsService } from './utils.service';
import { WorkingPeriodService } from './working-period.service';

export const services = [
    AppointmentLiveClassService,
    BlockUiService,
    ConvenienceService,
    DialogService,
    DirtyCheckService,
    InfoServiceService,
    PageHeaderService,
    PeriodizationItemsService,
    PeriodizationService,
    PhotoService,
    PlanDetailsService,
    PlanPriceListService,
    PromotionService,
    SocialMediaService,
    TagService,
    UploadService,
    UserPermissionsService,
    UtilsService,
    WorkingPeriodService,
];
