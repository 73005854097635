import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { AuthenticationGuard, LoginGuard } from '@guards';

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'agendamentos',
    loadChildren: () => import('./modules/schedules/entry-schedule/entry-schedule-routing.module')
      .then(m => m.EntryScheduleRoutingModule),
  },
  {
    path: 'aulas-online',
    loadChildren: () => import('./modules/schedules/online-class-schedule/online-class-schedule-routing.module')
      .then(m => m.OnlineClassScheduleRoutingModule),
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./modules/internal/internal.module')
      .then(m => m.InternalModule),
  }
];

const routing = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'corrected' });

@NgModule({
  imports: [routing],
  exports: [RouterModule]
})
export class AppRoutingModule { }
