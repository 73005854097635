import { tap, mergeMap, map, catchError } from 'rxjs/operators';
import { BaseResourceLoginModel } from '../models/base-resource-login.model';
import { Observable, of, EMPTY } from 'rxjs';
import { JSONUtil } from '@utils/json-util';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { Injector } from '@angular/core';
import { environment } from '@env/environment';
import { StorageService } from 'app/core/services/storage.service';
import { AlertMessage, MessageMap } from '@models';
import { AlertMessageService } from '../../core/services/alert-message.service';

export abstract class BaseResourseLoginService<T extends BaseResourceLoginModel> {

  protected http: HttpClient;
  protected apollo: Apollo;
  protected storageService: StorageService;
  protected alertMessageService: AlertMessageService;

  constructor(
    protected params: any,
    protected injector: Injector
  ) {
    this.http = this.injector.get(HttpClient);
    this.apollo = this.injector.get(Apollo);
    this.storageService = this.injector.get(StorageService);
    this.alertMessageService = this.injector.get(AlertMessageService);
  }

  public authenticate(resource: T) {

    const apollo = this.params.graphqlEnvironment ? this.apollo.use(this.params.graphqlEnvironment) : this.apollo;
    return this.getAppToken()
      .pipe(
        tap(response =>  this.storageService.set(this.params.key, response.jwt)),
        mergeMap(() => apollo.query({
          query: this.params.query,
          variables: resource
        }).pipe(
          map(JSONUtil.turnApolloMutable<any>()),
        )),
        catchError(() => {
          this.alertMessageService.showToastr(AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET));
          return EMPTY;
        })
      );
  }

  protected getAppToken(): Observable<any> {
    const url = environment.portal + this.params.url;
    return this.http.get(url);
  }
}
