import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PeriodizationDomain, PeriodizationFormData, AlertMessage, MessageMap, PeriodizationDomainList } from '@models';
import { AlertMessageService } from '@services';

@Component({
  selector: 'app-periodization-form',
  templateUrl: './periodization-form.component.html',
  styleUrls: ['./periodization-form.component.scss']
})
export class PeriodizationFormComponent implements OnInit {

  public periodizationEditForm: FormGroup;

  periodizationDomainList: PeriodizationDomainList[];
  dayPeriodization: PeriodizationDomain[];

  hasDescription = false;

  constructor(
    public dialogRef: MatDialogRef<PeriodizationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PeriodizationFormData,
    private alertMessageService: AlertMessageService
  ) { }

  ngOnInit() {
    const group = {};

    this.periodizationDomainList = this.data.periodizationDomainList;
    this.dayPeriodization = this.data.dayPeriodization;

    this.dayPeriodization.forEach(dp => {
      if (!dp.periodizationDomainVariable.nameAndDescription) {
        if (dp.periodizationDomainVariable.description) {
          dp.periodizationDomainVariable.nameAndDescription =
            dp.periodizationDomainVariable.name + ' - '
            + dp.periodizationDomainVariable.description;
        } else {
          dp.periodizationDomainVariable.nameAndDescription = dp.periodizationDomainVariable.name;
        }
      }
    });

    this.hasDescription = this.periodizationDomainList.some(
      pd => pd.periodizationDomainVariables.some(pdv => pdv.description)
    );

    this.periodizationDomainList.forEach(element => {
      element.periodizationDomainVariables.forEach(pdv => {
        if (!pdv.nameAndDescription) {
          pdv.nameAndDescription = pdv.description ? pdv.name + ' - ' + pdv.description : pdv.name;
        }
      });
      let equalDomain = null;
      if (this.dayPeriodization) {
        equalDomain = this.dayPeriodization.find(dp => dp.id === element.id);
      }

      group[element.id] = new FormControl(equalDomain ? equalDomain.periodizationDomainVariable : null, [Validators.required]);
    });
    this.periodizationEditForm = new FormGroup(group);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  editPeriodization() {
    if (this.periodizationEditForm.valid) {
      let emptyDayPeriodization;
      this.periodizationDomainList.forEach(periodizationDomainItem => {

        const equalDomain = this.dayPeriodization.find(dp => dp.id === periodizationDomainItem.id);

        if (!equalDomain) {
          emptyDayPeriodization = true;

          this.dayPeriodization.push({
            id: periodizationDomainItem.id,
            name: periodizationDomainItem.name,
            periodizationDomainVariable: {
              id: null,
              name: null,
              description: null,
              intensityColor: null,
            }
          });
        }
      });

      // Coloca na mesma ordenação de quando o elemento já esta preenchido para evitar bugs.
      if (emptyDayPeriodization) {
        if (this.periodizationDomainList.length > 2) {
          this.dayPeriodization.splice(2, 0, this.dayPeriodization.splice(1, 1)[0]);
          this.dayPeriodization.splice(3, 0, this.dayPeriodization.splice(2, 1)[0]);
        } else {
          this.dayPeriodization.splice(0, 0, this.dayPeriodization.splice(this.dayPeriodization.length - 1, 1)[0]);
        }
      }

      this.dayPeriodization.forEach(dp => {
        dp.periodizationDomainVariable = this.periodizationEditForm.controls['' + dp.id].value;
      });

      this.dialogRef.close(this.dayPeriodization);
    } else {
      this.periodizationEditForm.markAllAsTouched();
      this.alertMessageService.showToastr(AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO));
    }
  }

}
