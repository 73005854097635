<div class="filter-list-container mb-10" fxLayout="row" fxLayoutAlign="start center">
  <div class="label H5-LatoBold-C6 mr-10">{{ filterLabel }}</div>
  <ng-container *ngFor="let filter of filters">
    <div class="filter-box mr-4" (click)="selectFilter(filter)">
      <button class="bo-btn filter-text" [ngClass]="{ 
                    'B1-LatoBold-B': findFilter(filter),
                    'B1-LatoRegular-V': !findFilter(filter),  
                    'select-filter-box': findFilter(filter)
                }">
        {{ filter.name }}
      </button>
    </div>
  </ng-container>
</div>